export default function(state={}, action){
    if(action.componentId && !state[action.componentId]){
        state[action.componentId] = {
            loading : false,
            deleteLoading : false,
            deletedIndex : '',
            deleteError : false,
            deleteErrorMessage : '',
            hasError : false,
            errorMessage : '',
            uploadedFile : {},
            deletionPayload : {}
        }
    }
    switch(action.type){
        case 'SET_MULTIMEDIA_REDUCER':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    ...action.payload
                }
            }
        case 'MULTIMEDIA_LOADING':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    loading : true,
                    hasError : false,
                    errorMessage : '',
                    uploadedFile : {}
                }
            }
        case 'MULTIMEDIA_DELETE_LOADING':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    deleteLoading : true,
                    deleteError : false,
                    deleteErrorMessage : '',
                    deletedIndex : action.deletedIndex,
                    deletionPayload : {}
                }
            }
        case 'MULTIMEDIA_DELETE_ERROR':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    deleteLoading : false,
                    deleteError : true,
                    deleteErrorMessage : action.payload.errorMessage,
                    deletedIndex : action.deletedIndex
                }
            }
        case 'MULTIMEDIA_DELETE_SUCCESS':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    deleteLoading : false,
                    deleteError : false,
                    deleteErrorMessage : '',
                    deletedIndex : action.deletedIndex,
                    deletionPayload : action.payload
                }
            }
        case 'MULTIMEDIA_ERROR':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    loading : false,
                    hasError : true,
                    errorMessage : action.payload.errorMessage,
                    uploadedFile : {}
                }
            }
        case 'MULTIMEDIA_SUCCESS':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    loading : false,
                    hasError : false,
                    errorMessage : '',
                    uploadedFile : action.payload
                }
            }
        default:
            return state;
    }
}