import update from 'immutability-helper';
import {cloneDeep} from 'lodash';

export default function (data={}, value, path='', skipStringify=false){
    try{
        let splitPath = path.split('.');
        let mapStack = {};
        for (let i = 0; i < splitPath.length; i++){
            mapStack[i] = splitPath[i];
        }
        let preservedBase = typeof data === 'object' && !skipStringify ?
        cloneDeep(data) : data;
        let baseObj = typeof data === 'object' && !skipStringify ?
        cloneDeep(data) : data;

        let objectStack2 = {}, objStackKeys=[];

        for (let k = 0; k < Object.keys(mapStack).length; k++){
            let key = Object.keys(mapStack)[k];
            let mapText = `${k}.${mapStack[key]}`;
            let pureMapText = mapStack[key];
            objStackKeys.push(mapText)
            if(
                k === (Object.keys(mapStack).length - 1)
            ){
                objectStack2[mapText] = {
                    isArray : Array.isArray(value),
                    value
                }; 
            }else{
                let validPath = typeof baseObj[pureMapText] !== 'undefined';
                objectStack2[mapText] = {
                    isArray : Array.isArray(baseObj[pureMapText]),
                    value : validPath ? baseObj[pureMapText] : {}
                };
                baseObj = validPath ? baseObj[pureMapText] : {};
            }
        }

        //revere reconstruct the object
        let stack2Length = objStackKeys.length;
        let reconstructedObject = objectStack2[objStackKeys[stack2Length - 1]]['value'];
        for (let i = stack2Length - 2; i >= 0; i--){
            let key = objStackKeys[i];
            let isArray = objectStack2[key]['isArray'];
            if(isArray){
                reconstructedObject = update(objectStack2[key]['value'], {$splice : [[
                    Number(objStackKeys[i + 1].split('.')[1]),
                    1,
                    reconstructedObject
                ]]})
            }else{
                reconstructedObject = {
                    ...objectStack2[key]['value'],
                    [objStackKeys[i + 1].split('.')[1]] : reconstructedObject               
                }
            }
        }

        return {
            ...preservedBase,
            [objStackKeys[0].split('.')[1]] : reconstructedObject
        };
    }catch(e){
        console.log('/utils/data/dotNotationObjectUpdate catch error', e);
        return data;
    }
}