import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const uploadFile = (data, componentId='') => async dispatch => {
    dispatch({
        type : 'MULTIMEDIA_LOADING',
        componentId
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/multimedia/upload`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'MULTIMEDIA_SUCCESS',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'MULTIMEDIA_ERROR',
            payload : request,
            componentId
        })
    }
}

export const deleteMultimediaFile = (data, componentId='', deletedIndex='') => async dispatch => {
    dispatch({
        type : 'MULTIMEDIA_DELETE_LOADING',
        componentId,
        deletedIndex
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/multimedia/deletefiles`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'MULTIMEDIA_DELETE_SUCCESS',
            payload : request,
            componentId,
            deletedIndex
        });
    }else{
        dispatch({
            type : 'MULTIMEDIA_DELETE_ERROR',
            payload : request,
            componentId,
            deletedIndex
        })
    }
} 

export const setMultimediaReducer = (data, componentId='') => dispatch => {
    dispatch({
        type : 'SET_MULTIMEDIA_REDUCER',
        payload : data,
        componentId
    })
}